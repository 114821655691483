import React, { useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'src/components/error-fallback/error-fallback';
import LanguageProvider from 'src/config/language-provider';
import { ClientConfigProvider } from 'src/config/provider';
import { muiThemeOverride } from 'src/styles/theme';
import { QueryParamProvider } from 'use-query-params';

import { StyleProvider } from '@clubspark-react/clubspark-react-tools';

import initDefaultClient from './client';

export const wrapRootBrowser = ({ element }) => {
  return (
    <ClientConfigProvider>
      <LanguageProvider>
        <QueryParamProvider>
          <WrapRoot element={element} />
        </QueryParamProvider>
      </LanguageProvider>
    </ClientConfigProvider>
  );
};

export const wrapRootSSR = ({ element }) => {
  return <WrapRoot element={element} />;
};

const WrapRoot: React.FC<{ element: any }> = ({ element }) => {
  // Instantiating client in `wrapRootElement` handler ensures:
  //  - there is fresh client for each SSR page
  //  - it will be called only once in browser, when React mounts
  const client = useMemo(initDefaultClient, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiThemeOverride}>
          <StyleProvider>
            <ApolloProvider client={client}>
              <DndProvider backend={HTML5Backend}>{element}</DndProvider>
            </ApolloProvider>
          </StyleProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};
