exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[player]-points-player-index-tsx": () => import("./../../../src/pages/player-points/[player]/index.tsx" /* webpackChunkName: "component---src-pages-[player]-points-player-index-tsx" */),
  "component---src-pages-access-denied-tsx": () => import("./../../../src/pages/access-denied.tsx" /* webpackChunkName: "component---src-pages-access-denied-tsx" */),
  "component---src-pages-areas-area-ids-tsx": () => import("./../../../src/pages/areas/[...areaIds].tsx" /* webpackChunkName: "component---src-pages-areas-area-ids-tsx" */),
  "component---src-pages-areas-index-tsx": () => import("./../../../src/pages/areas/index.tsx" /* webpackChunkName: "component---src-pages-areas-index-tsx" */),
  "component---src-pages-areas-manage-coordinators-index-tsx": () => import("./../../../src/pages/areas/manage-coordinators/index.tsx" /* webpackChunkName: "component---src-pages-areas-manage-coordinators-index-tsx" */),
  "component---src-pages-coaches-index-tsx": () => import("./../../../src/pages/coaches/index.tsx" /* webpackChunkName: "component---src-pages-coaches-index-tsx" */),
  "component---src-pages-college-membership-index-tsx": () => import("./../../../src/pages/college/membership/index.tsx" /* webpackChunkName: "component---src-pages-college-membership-index-tsx" */),
  "component---src-pages-college-team-add-team-member-tsx": () => import("./../../../src/pages/college/team/add-team-member.tsx" /* webpackChunkName: "component---src-pages-college-team-add-team-member-tsx" */),
  "component---src-pages-college-team-index-tsx": () => import("./../../../src/pages/college/team/index.tsx" /* webpackChunkName: "component---src-pages-college-team-index-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ita-merge-players-index-tsx": () => import("./../../../src/pages/ita-merge-players/index.tsx" /* webpackChunkName: "component---src-pages-ita-merge-players-index-tsx" */),
  "component---src-pages-ita-players-[profile]-box-score-index-tsx": () => import("./../../../src/pages/ita-players/[profile]/[boxScore]/index.tsx" /* webpackChunkName: "component---src-pages-ita-players-[profile]-box-score-index-tsx" */),
  "component---src-pages-leagues-index-tsx": () => import("./../../../src/pages/leagues/index.tsx" /* webpackChunkName: "component---src-pages-leagues-index-tsx" */),
  "component---src-pages-leagues-leagues-coordinators-index-tsx": () => import("./../../../src/pages/leagues/leagues-coordinators/index.tsx" /* webpackChunkName: "component---src-pages-leagues-leagues-coordinators-index-tsx" */),
  "component---src-pages-leagues-level-config-edit-tsx": () => import("./../../../src/pages/leagues/level-config/edit.tsx" /* webpackChunkName: "component---src-pages-leagues-level-config-edit-tsx" */),
  "component---src-pages-leagues-level-config-index-tsx": () => import("./../../../src/pages/leagues/level-config/index.tsx" /* webpackChunkName: "component---src-pages-leagues-level-config-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-lta-memberships-[membership]-edit-tsx": () => import("./../../../src/pages/lta-memberships/[membership]/edit.tsx" /* webpackChunkName: "component---src-pages-lta-memberships-[membership]-edit-tsx" */),
  "component---src-pages-lta-memberships-index-tsx": () => import("./../../../src/pages/lta-memberships/index.tsx" /* webpackChunkName: "component---src-pages-lta-memberships-index-tsx" */),
  "component---src-pages-lta-memberships-lta-member-tsx": () => import("./../../../src/pages/lta-memberships/lta-member.tsx" /* webpackChunkName: "component---src-pages-lta-memberships-lta-member-tsx" */),
  "component---src-pages-lta-memberships-lta-membership-details-tsx": () => import("./../../../src/pages/lta-memberships/lta-membership-details.tsx" /* webpackChunkName: "component---src-pages-lta-memberships-lta-membership-details-tsx" */),
  "component---src-pages-memberships-create-membership-tsx": () => import("./../../../src/pages/memberships/create-membership.tsx" /* webpackChunkName: "component---src-pages-memberships-create-membership-tsx" */),
  "component---src-pages-memberships-index-tsx": () => import("./../../../src/pages/memberships/index.tsx" /* webpackChunkName: "component---src-pages-memberships-index-tsx" */),
  "component---src-pages-memberships-member-member-tsx": () => import("./../../../src/pages/memberships/member/member.tsx" /* webpackChunkName: "component---src-pages-memberships-member-member-tsx" */),
  "component---src-pages-memberships-member-tsx": () => import("./../../../src/pages/memberships/member.tsx" /* webpackChunkName: "component---src-pages-memberships-member-tsx" */),
  "component---src-pages-memberships-membership-tsx": () => import("./../../../src/pages/memberships/membership.tsx" /* webpackChunkName: "component---src-pages-memberships-membership-tsx" */),
  "component---src-pages-memberships-users-tsx": () => import("./../../../src/pages/memberships/users.tsx" /* webpackChunkName: "component---src-pages-memberships-users-tsx" */),
  "component---src-pages-oauth-callback-index-tsx": () => import("./../../../src/pages/oauth-callback/index.tsx" /* webpackChunkName: "component---src-pages-oauth-callback-index-tsx" */),
  "component---src-pages-organisations-checklists-index-tsx": () => import("./../../../src/pages/organisations/checklists/index.tsx" /* webpackChunkName: "component---src-pages-organisations-checklists-index-tsx" */),
  "component---src-pages-organisations-manage-organisations-index-tsx": () => import("./../../../src/pages/organisations/manage-organisations/index.tsx" /* webpackChunkName: "component---src-pages-organisations-manage-organisations-index-tsx" */),
  "component---src-pages-organisations-manage-organisations-org-id-organisation-profile-page-tsx": () => import("./../../../src/pages/organisations/manage-organisations/[orgId]/organisation-profile-page.tsx" /* webpackChunkName: "component---src-pages-organisations-manage-organisations-org-id-organisation-profile-page-tsx" */),
  "component---src-pages-organisations-manage-schools-index-tsx": () => import("./../../../src/pages/organisations/manage-schools/index.tsx" /* webpackChunkName: "component---src-pages-organisations-manage-schools-index-tsx" */),
  "component---src-pages-organisations-manage-schools-invite-admin-tsx": () => import("./../../../src/pages/organisations/manage-schools/invite-admin.tsx" /* webpackChunkName: "component---src-pages-organisations-manage-schools-invite-admin-tsx" */),
  "component---src-pages-player-points-index-tsx": () => import("./../../../src/pages/player-points/index.tsx" /* webpackChunkName: "component---src-pages-player-points-index-tsx" */),
  "component---src-pages-players-[profile]-adjust-points-tsx": () => import("./../../../src/pages/players/[profile]/adjust-points.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-adjust-points-tsx" */),
  "component---src-pages-players-[profile]-box-score-box-score-tsx": () => import("./../../../src/pages/players/[profile]/boxScore/[boxScore].tsx" /* webpackChunkName: "component---src-pages-players-[profile]-box-score-box-score-tsx" */),
  "component---src-pages-players-[profile]-edit-suspension-tsx": () => import("./../../../src/pages/players/[profile]/edit-suspension.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-edit-suspension-tsx" */),
  "component---src-pages-players-[profile]-index-tsx": () => import("./../../../src/pages/players/[profile]/index.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-index-tsx" */),
  "component---src-pages-players-[profile]-suspend-player-tsx": () => import("./../../../src/pages/players/[profile]/suspend-player.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-suspend-player-tsx" */),
  "component---src-pages-players-[profile]-suspensions-[suspension]-tsx": () => import("./../../../src/pages/players/[profile]/suspensions/[suspension].tsx" /* webpackChunkName: "component---src-pages-players-[profile]-suspensions-[suspension]-tsx" */),
  "component---src-pages-players-[profile]-suspensions-add-violation-tsx": () => import("./../../../src/pages/players/[profile]/suspensions/add-violation.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-suspensions-add-violation-tsx" */),
  "component---src-pages-players-[profile]-suspensions-violations-[violation]-tsx": () => import("./../../../src/pages/players/[profile]/suspensions/violations/[violation].tsx" /* webpackChunkName: "component---src-pages-players-[profile]-suspensions-violations-[violation]-tsx" */),
  "component---src-pages-players-[profile]-suspensions-violations-add-tsx": () => import("./../../../src/pages/players/[profile]/suspensions/violations/add.tsx" /* webpackChunkName: "component---src-pages-players-[profile]-suspensions-violations-add-tsx" */),
  "component---src-pages-players-add-suspension-points-tsx": () => import("./../../../src/pages/players/add-suspension-points.tsx" /* webpackChunkName: "component---src-pages-players-add-suspension-points-tsx" */),
  "component---src-pages-players-index-tsx": () => import("./../../../src/pages/players/index.tsx" /* webpackChunkName: "component---src-pages-players-index-tsx" */),
  "component---src-pages-programmes-index-tsx": () => import("./../../../src/pages/programmes/index.tsx" /* webpackChunkName: "component---src-pages-programmes-index-tsx" */),
  "component---src-pages-rankings-group-tsx": () => import("./../../../src/pages/rankings/group.tsx" /* webpackChunkName: "component---src-pages-rankings-group-tsx" */),
  "component---src-pages-rankings-index-tsx": () => import("./../../../src/pages/rankings/index.tsx" /* webpackChunkName: "component---src-pages-rankings-index-tsx" */),
  "component---src-pages-rankings-ranking-tsx": () => import("./../../../src/pages/rankings/ranking.tsx" /* webpackChunkName: "component---src-pages-rankings-ranking-tsx" */),
  "component---src-pages-reports-history-tsx": () => import("./../../../src/pages/reports/history.tsx" /* webpackChunkName: "component---src-pages-reports-history-tsx" */),
  "component---src-pages-reports-index-tsx": () => import("./../../../src/pages/reports/index.tsx" /* webpackChunkName: "component---src-pages-reports-index-tsx" */),
  "component---src-pages-reports-ranking-reports-tsx": () => import("./../../../src/pages/reports/ranking-reports.tsx" /* webpackChunkName: "component---src-pages-reports-ranking-reports-tsx" */),
  "component---src-pages-saas-memberships-[membership]-[organisation]-index-tsx": () => import("./../../../src/pages/saas-memberships/[membership]/[organisation]/index.tsx" /* webpackChunkName: "component---src-pages-saas-memberships-[membership]-[organisation]-index-tsx" */),
  "component---src-pages-saas-memberships-[membership]-edit-index-tsx": () => import("./../../../src/pages/saas-memberships/[membership]/edit/index.tsx" /* webpackChunkName: "component---src-pages-saas-memberships-[membership]-edit-index-tsx" */),
  "component---src-pages-saas-memberships-[membership]-index-tsx": () => import("./../../../src/pages/saas-memberships/[membership]/index.tsx" /* webpackChunkName: "component---src-pages-saas-memberships-[membership]-index-tsx" */),
  "component---src-pages-saas-memberships-add-membership-index-tsx": () => import("./../../../src/pages/saas-memberships/add-membership/index.tsx" /* webpackChunkName: "component---src-pages-saas-memberships-add-membership-index-tsx" */),
  "component---src-pages-saas-memberships-index-tsx": () => import("./../../../src/pages/saas-memberships/index.tsx" /* webpackChunkName: "component---src-pages-saas-memberships-index-tsx" */),
  "component---src-pages-saas-memberships-settings-index-tsx": () => import("./../../../src/pages/saas-memberships/settings/index.tsx" /* webpackChunkName: "component---src-pages-saas-memberships-settings-index-tsx" */),
  "component---src-pages-saas-memberships-settings-invoice-details-tsx": () => import("./../../../src/pages/saas-memberships/settings/invoice-details.tsx" /* webpackChunkName: "component---src-pages-saas-memberships-settings-invoice-details-tsx" */),
  "component---src-pages-saas-memberships-settings-stripe-setup-tsx": () => import("./../../../src/pages/saas-memberships/settings/stripe-setup.tsx" /* webpackChunkName: "component---src-pages-saas-memberships-settings-stripe-setup-tsx" */),
  "component---src-pages-saas-memberships-settings-terms-and-conditions-tsx": () => import("./../../../src/pages/saas-memberships/settings/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-saas-memberships-settings-terms-and-conditions-tsx" */),
  "component---src-pages-schools-[school]-edit-tsx": () => import("./../../../src/pages/schools/[school]/edit.tsx" /* webpackChunkName: "component---src-pages-schools-[school]-edit-tsx" */),
  "component---src-pages-schools-add-tsx": () => import("./../../../src/pages/schools/add.tsx" /* webpackChunkName: "component---src-pages-schools-add-tsx" */),
  "component---src-pages-schools-index-tsx": () => import("./../../../src/pages/schools/index.tsx" /* webpackChunkName: "component---src-pages-schools-index-tsx" */),
  "component---src-pages-staff-add-tsx": () => import("./../../../src/pages/staff/add.tsx" /* webpackChunkName: "component---src-pages-staff-add-tsx" */),
  "component---src-pages-staff-edit-staff-tsx": () => import("./../../../src/pages/staff/edit-staff.tsx" /* webpackChunkName: "component---src-pages-staff-edit-staff-tsx" */),
  "component---src-pages-staff-index-tsx": () => import("./../../../src/pages/staff/index.tsx" /* webpackChunkName: "component---src-pages-staff-index-tsx" */),
  "component---src-pages-suspension-codes-add-tsx": () => import("./../../../src/pages/suspension/codes/add.tsx" /* webpackChunkName: "component---src-pages-suspension-codes-add-tsx" */),
  "component---src-pages-suspension-codes-edit-tsx": () => import("./../../../src/pages/suspension/codes/edit.tsx" /* webpackChunkName: "component---src-pages-suspension-codes-edit-tsx" */),
  "component---src-pages-suspension-codes-index-tsx": () => import("./../../../src/pages/suspension/codes/index.tsx" /* webpackChunkName: "component---src-pages-suspension-codes-index-tsx" */),
  "component---src-pages-suspension-index-tsx": () => import("./../../../src/pages/suspension/index.tsx" /* webpackChunkName: "component---src-pages-suspension-index-tsx" */),
  "component---src-pages-suspension-player-edit-tsx": () => import("./../../../src/pages/suspension/player/edit.tsx" /* webpackChunkName: "component---src-pages-suspension-player-edit-tsx" */),
  "component---src-pages-suspension-player-index-tsx": () => import("./../../../src/pages/suspension/player/index.tsx" /* webpackChunkName: "component---src-pages-suspension-player-index-tsx" */),
  "component---src-pages-suspension-points-tsx": () => import("./../../../src/pages/suspension/points.tsx" /* webpackChunkName: "component---src-pages-suspension-points-tsx" */),
  "component---src-pages-suspension-to-be-used-tsx": () => import("./../../../src/pages/suspension/to-be-used.tsx" /* webpackChunkName: "component---src-pages-suspension-to-be-used-tsx" */),
  "component---src-pages-tournaments-calendar-tsx": () => import("./../../../src/pages/tournaments/calendar.tsx" /* webpackChunkName: "component---src-pages-tournaments-calendar-tsx" */),
  "component---src-pages-tournaments-directors-index-tsx": () => import("./../../../src/pages/tournaments/directors/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-directors-index-tsx" */),
  "component---src-pages-tournaments-directors-invite-tsx": () => import("./../../../src/pages/tournaments/directors/invite.tsx" /* webpackChunkName: "component---src-pages-tournaments-directors-invite-tsx" */),
  "component---src-pages-tournaments-directors-profile-tsx": () => import("./../../../src/pages/tournaments/directors/profile.tsx" /* webpackChunkName: "component---src-pages-tournaments-directors-profile-tsx" */),
  "component---src-pages-tournaments-edit-tournament-tsx": () => import("./../../../src/pages/tournaments/edit-tournament.tsx" /* webpackChunkName: "component---src-pages-tournaments-edit-tournament-tsx" */),
  "component---src-pages-tournaments-events-tsx": () => import("./../../../src/pages/tournaments/events.tsx" /* webpackChunkName: "component---src-pages-tournaments-events-tsx" */),
  "component---src-pages-tournaments-financial-reports-tsx": () => import("./../../../src/pages/tournaments/financial-reports.tsx" /* webpackChunkName: "component---src-pages-tournaments-financial-reports-tsx" */),
  "component---src-pages-tournaments-groups-create-tournament-group-tsx": () => import("./../../../src/pages/tournaments/groups/create-tournament-group.tsx" /* webpackChunkName: "component---src-pages-tournaments-groups-create-tournament-group-tsx" */),
  "component---src-pages-tournaments-groups-group-tsx": () => import("./../../../src/pages/tournaments/groups/group.tsx" /* webpackChunkName: "component---src-pages-tournaments-groups-group-tsx" */),
  "component---src-pages-tournaments-groups-index-tsx": () => import("./../../../src/pages/tournaments/groups/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-groups-index-tsx" */),
  "component---src-pages-tournaments-index-tsx": () => import("./../../../src/pages/tournaments/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-index-tsx" */),
  "component---src-pages-tournaments-level-config-edit-tsx": () => import("./../../../src/pages/tournaments/level-config/edit.tsx" /* webpackChunkName: "component---src-pages-tournaments-level-config-edit-tsx" */),
  "component---src-pages-tournaments-level-config-index-tsx": () => import("./../../../src/pages/tournaments/level-config/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-level-config-index-tsx" */),
  "component---src-pages-tournaments-payouts-report-index-tsx": () => import("./../../../src/pages/tournaments/payouts-report/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-payouts-report-index-tsx" */),
  "component---src-pages-tournaments-payouts-report-payouts-tsx": () => import("./../../../src/pages/tournaments/payouts-report/payouts.tsx" /* webpackChunkName: "component---src-pages-tournaments-payouts-report-payouts-tsx" */),
  "component---src-pages-tournaments-settings-index-tsx": () => import("./../../../src/pages/tournaments/settings/index.tsx" /* webpackChunkName: "component---src-pages-tournaments-settings-index-tsx" */),
  "component---src-pages-tournaments-settings-scorecard-templates-create-template-tsx": () => import("./../../../src/pages/tournaments/settings/scorecard-templates/create-template.tsx" /* webpackChunkName: "component---src-pages-tournaments-settings-scorecard-templates-create-template-tsx" */),
  "component---src-pages-tournaments-settings-scorecard-templates-edit-tsx": () => import("./../../../src/pages/tournaments/settings/scorecard-templates/edit.tsx" /* webpackChunkName: "component---src-pages-tournaments-settings-scorecard-templates-edit-tsx" */),
  "component---src-pages-tournaments-tournaments-report-tsx": () => import("./../../../src/pages/tournaments/tournaments-report.tsx" /* webpackChunkName: "component---src-pages-tournaments-tournaments-report-tsx" */)
}

