import React from 'react';

import cx from 'classnames';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';

import * as styles from './icon.module.less';

const icons = {
  dashboard: 'nav/dashboard',
  facilities: 'nav/facilities',
  leagues: 'nav/leagues',
  membership: 'nav/membership',
  organisations: 'nav/organisations',
  programming: 'nav/programming',
  reports: 'nav/reports',
  tournaments: 'nav/tourn',
  users: 'nav/users',
  ranking: 'nav/ranking',
  rankings: 'nav/rankings-nav',
  players: 'nav/players',
  schools: 'nav/schools',
  areas: 'nav/areas',
  coaches: 'nav/coaches',
  events: 'nav/events',
  'sm-help-circle': '16px/help-circle',
  'sm-add': '16px/add',
  'sm-close': '16px/close',
  'sm-clock': '16px/clock',
  'sm-clock-grey': '16px/clock-grey',
  'sm-down': '16px/down',
  'sm-left': '16px/left',
  'sm-right': '16px/right',
  'sm-search': '16px/search',
  'sm-tick': '16px/tick',
  'sm-up': '16px/up',
  'sm-link': '16px/link',
  'sm-delete': '16px/delete',
  'sm-download': '16px/download',
  'sm-edit': '16px/edit',
  'sm-venue': '16px/venue',
  'sm-warning': '16px/warning',
  'sm-reset': '16px/reset',
  'sm-padlock': '16px/padlock',
  'sm-columns': '16px/columns',
  'sm-new-window': '16px/new-window',
  'sm-close-circle-inverted': '16px/close-circle-inverted',
  'md-dollar-circle': '24px/dollar-circle',
  'md-help-circle': '24px/help-circle',
  'md-double-arrow': '24px/double-arrow',
  'md-dollar': '24px/dollar',
  'md-pound-circle': '24px/pound-circle',
  'md-pound': '24px/pound',
  'md-launch': '24px/launch',
  'md-add-circle-full': '24px/add-circle-full',
  'md-add-circle': '24px/add-circle',
  'md-add': '24px/add',
  'md-annotation': '24px/annotation',
  'md-tooltip-annotation': '24px/tooltip-annotation',
  'md-alarm': '24px/alarm',
  'md-alert': '24px/alert',
  'md-alert-circle': '24px/alert-circle',
  'md-calendar': '24px/calendar',
  'md-clock': '24px/clock',
  'md-clock-end': '24px/clock-end',
  'md-close-circle': '24px/close-circle',
  'md-close': '24px/close',
  'md-delete': '24px/delete',
  'md-delete-red': '24px/delete-red',
  'md-download': '24px/download',
  'md-fullscreen': '24px/fullscreen',
  'md-hourglass': '24px/hourglass',
  'md-invite-link': '24px/invite-link',
  'md-link': '24px/link',
  'md-mail': '24px/mail',
  'md-remove-circle': '24px/remove-circle',
  'md-settings': '24px/settings',
  'md-tick-circle': '24px/tick-circle',
  'md-tick': '24px/tick',
  'md-dollar-circ': '24px/dollar-circ',
  'md-users': '24px/users',
  'md-mixed-chart': '24px/mixed-chart',
  'md-more-options': '24px/more-options',
  'md-new-window': '24px/new-window',
  'md-swap-vertical': '24px/swap-vertical',
  'md-penalty': '24px/penalty',
  'md-alert-triangle': '24px/alert-triangle',
  'lg-clock': '48px/clock',
  'lg-empty-state': '48px/empty-state',
  'xl-payment': '72px/payment',
  'xl-tournament': '72px/tournament',
  'xl-user': '72px/user',
  'xl-document': '72px/document',
  'xl-rankings': '72px/rankings',
  'xl-team': '72px/team',
  'xl-staff': '72px/staff',
  'xl-photo': '72px/photo',
};

export type IconName = keyof typeof icons;

export interface IconProps extends Omit<React.SVGProps<SVGSVGElement>, 'spacing'>, SpacingProps {
  name: IconName;
  light?: boolean;
}

const Icon: React.FC<IconProps> = ({ name, light, spacing, className, ...props }) => {
  const spacingClass = useSpacing(spacing);
  const fileName = icons[name];
  const SVGIcon: React.FC<React.SVGProps<SVGSVGElement>> | undefined =
    fileName && require(`src/images/icons/${fileName}.svg`);
  return SVGIcon ? (
    <SVGIcon className={cx(spacingClass, className, { [styles.lightStroke]: light })} {...props} />
  ) : null;
};

export default Icon;
