import React, { useEffect, useMemo, useState } from 'react';

import { InitOptions } from 'i18next';
import Spinner from 'src/components/spinner/spinner';
import i18n from 'src/utils/i18n';

import { getClientConfig } from './config';

export const LanguageProvider: React.FC = ({ children }) => {
  const [languageLoaded, setLanguageLoaded] = useState(false);
  const { language, fallbackLanguages } = useMemo(getClientConfig, []);

  useEffect(() => {
    if (language || fallbackLanguages) {
      const initOptions: InitOptions = {};

      if (language) initOptions.lng = language;
      if (fallbackLanguages) initOptions.fallbackLng = fallbackLanguages;

      i18n.init(initOptions, () => setLanguageLoaded(true));
    } else {
      setLanguageLoaded(true);
    }
  }, [language, fallbackLanguages]);

  return <>{languageLoaded ? children : <Spinner fullScreen />}</>;
};

export default LanguageProvider;
