import React from 'react';

import cx from 'classnames';
import SpinnerSvg from 'src/images/svg-components/spinner.svg';

import * as styles from './spinner.module.less';

interface Props {
  fluid?: boolean;
  size?: 'sm' | 'md' | 'lg';
  fullScreen?: boolean;
}

const Spinner: React.FC<Props> = ({ fluid, size = 'lg', fullScreen = false }) => {
  const Wrapper = fullScreen ? FullScreenWrapper : NoWrap;

  return (
    <Wrapper>
      <SpinnerSvg
        data-testid="spinner"
        className={cx(fluid ? styles.fluidSpinner : styles.spinner, {
          [styles.smSpinner]: !fluid && size === 'sm',
          [styles.mdSpinner]: !fluid && size === 'md',
          [styles.lgSpinner]: !fluid && size === 'lg',
        })}
      />
    </Wrapper>
  );
};

const FullScreenWrapper: React.FC = ({ children }) => <div className={'ccSpinnerFullScreen'}>{children}</div>;

const NoWrap: React.FC = ({ children }) => <>{children}</>;

export default Spinner;
