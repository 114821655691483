import ROUTES from 'src/utils/routes';

import { StaffScope } from './enums';

export interface AccessRule {
  route: string;
  scope: StaffScope[];
  redirect?: string;
  withDynamicId?: boolean;
  requiresGlobalAdmin?: boolean;
}

// Rules are checked in order of array, put more specific paths at the top.
// Not all routes need to be listed, /tournaments rule covers any url beggining with /tournaments
// unless a more specific rule precedes it.
export const ACCESS_RULES: AccessRule[] = [
  {
    route: ROUTES.ADD_STAFF,
    scope: [StaffScope.STAFF, StaffScope.SUPERADMIN],
    redirect: '/staff',
  },
  {
    route: ROUTES.STAFF,
    withDynamicId: true, // edit staff page
    scope: [StaffScope.STAFF, StaffScope.SUPERADMIN],
    redirect: '/staff',
  },
  { route: ROUTES.STAFF, scope: [StaffScope.STAFF] },
  {
    route: ROUTES.LEVEL_CONFIG,
    scope: [StaffScope.TOURNAMENTS, StaffScope.SUPERADMIN],
  },
  { route: ROUTES.TOURNAMENT_SETTINGS, scope: [StaffScope.TOURNAMENTS] },
  {
    route: ROUTES.PAYOUTS,
    scope: [StaffScope.TOURNAMENTS, StaffScope.SUPERADMIN],
  },
  { route: ROUTES.TOURNAMENTS, scope: [StaffScope.TOURNAMENTS] },
  { route: ROUTES.RANKINGS, scope: [StaffScope.RANKINGS] },
  { route: ROUTES.REPORTS, scope: [StaffScope.REPORTS] },
  { route: ROUTES.PLAYERS, scope: [StaffScope.PLAYERS] },
  { route: ROUTES.MERGE_PLAYERS, scope: [StaffScope.SUPERADMIN], requiresGlobalAdmin: true },
  { route: ROUTES.SAAS_MEMBERSHIPS, scope: [StaffScope.MEMBERSHIPS] },
  { route: ROUTES.MANAGE_SCHOOLS, scope: [] },
  { route: ROUTES.ORGANISATIONS, scope: [StaffScope.ORGANISATIONS] },
  { route: ROUTES.PROGRAMMES, scope: [StaffScope.PROGRAMMES] },
  { route: ROUTES.EVENTS, scope: [StaffScope.EVENTS] },
  { route: ROUTES.COACHES, scope: [StaffScope.COACHES] },
  { route: ROUTES.LEAGUES, scope: [StaffScope.LEAGUES] },
];
